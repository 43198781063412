import React from 'react';
import { graphql, useStaticQuery, navigate } from 'gatsby';
import { Helmet } from 'react-helmet';
import Splash from '../components/Splash/Splash';
import {
  storeLocale,
  getStoredLocale,
} from '../functions/langUtils';
// import { getPreferredLang } from '../functions/getPreferredLang';
import { isSSR } from '../functions/isSSR';

const Index = () => {
  const data = useStaticQuery(graphql`
    query {
      allBuildLanguage {
        nodes {
          locale
        }
      }
      datoCmsSplashPage {
        metaTags: seo {
          title
          description
        }
      }
    }
  `);

  const {
    allBuildLanguage,
    datoCmsSplashPage: { metaTags },
  } = data;

  // const appLangCodes = [...locales];

  const appLangCodes = allBuildLanguage.nodes.map((item) => item.locale);

  const storedLocale = getStoredLocale();
  if (!isSSR) {
    const isLangStored = appLangCodes.some((lang) => lang === storedLocale);

    if (isLangStored) {
      // a valid language is already stored, redirect
      navigate(`/${storedLocale.toLowerCase()}`);
    }
  }

  // if (storedLocale) {
  //   return <div></div>;
  // }

  const onContinue = (locale) => {
    // console.log("onContinue", locale);
    storeLocale(locale);
    navigate(`/${locale.toLowerCase()}`);
  };

  return (
    <>
      <Helmet>
        <title>{metaTags.title}</title>
        <meta name="description" content={metaTags.description} />
      </Helmet>
      <Splash onContinue={onContinue} />
    </>
  );
};

export default Index;
