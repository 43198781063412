import React, { useState, useEffect } from 'react';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import IconArrowDown from '../Layout/Icons/IconArrowDown';
import cn from 'classnames';
import style from './select.mod.scss';

const Select = ({ items, getFilterSelect, noSelectionLabel = 'All', className }) => {
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState('');

  const handleOnClick = () => {
    setOpen(true);
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  const handleSetSelected = (params) => {
    setSelected(params);
    setOpen(!open);
  };

  useEffect(() => {
    getFilterSelect(selected);
  }, [selected]);

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div className={cn(style.select, className)}>
        <div className={style.select__input} onClick={handleOnClick}>
          <label>{selected ? selected : noSelectionLabel}</label>
          <div>
            <IconArrowDown />
          </div>
        </div>
        {open ? (
          <ul className={style.select__dropdown}>
            <li
              value={''}
              onClick={() => handleSetSelected('')}
            >
              {noSelectionLabel}
            </li>
            {items.map((listItem) => (
              <li
                key={listItem}
                value={listItem}
                onClick={() => handleSetSelected(listItem)}
              >
                {listItem}
              </li>
            ))}
          </ul>
        ) : null}
      </div>
    </ClickAwayListener>
  );
};

export default Select;
